<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M11.6663 1.70654V6.70654H16.6663M12.083 1.70654H4.99967C4.55765 1.70654 4.13372 1.88214 3.82116 2.1947C3.5086 2.50726 3.33301 2.93118 3.33301 3.37321V16.7065C3.33301 17.1486 3.5086 17.5725 3.82116 17.8851C4.13372 18.1976 4.55765 18.3732 4.99967 18.3732H14.9997C15.4417 18.3732 15.8656 18.1976 16.1782 17.8851C16.4907 17.5725 16.6663 17.1486 16.6663 16.7065V6.28988L12.083 1.70654Z"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
