// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { ru } from 'vuetify/lib/locale'

export default createVuetify(
  {
    locale: {
      locales: { ru },
      current: 'ru',
    },
    theme: {
      themes: {
        light: {
          variables: {
            'v-btn-border-radius': '32px',
          },
          colors: {
            primary: '#F0591E',
          },
        },
      },
    },
  },
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
