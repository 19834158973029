<template>
  <TaskCategoriesTabs />
</template>
<script setup>
import TaskCategoriesTabs from '@/components/Tasks/TaskCategoriesTabs'
import store from '@/store'
import { onMounted } from 'vue'

const setPageTitle = () => {
  store.commit('app/setPageTitle', 'Мои задачи')
}

onMounted(setPageTitle)
</script>
