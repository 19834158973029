<template>
  <v-navigation-drawer v-model="drawer" :width="288" class="menu" app>
    <div class="menu-wrapper">
      <div class="top-menu-wrapper">
        <div class="logo-wrapper">
          <router-link to="/">
            <app-logo />
          </router-link>
        </div>
        <div class="navbar">
          <v-list-item
            v-for="tab in tabs"
            :key="tab.id"
            :to="tab.to"
            active-class="active"
            router
            class="d-flex align-center menu-item w-100"
            full
          >
            <div class="w-100 d-flex align-center justify-space-between">
              <div class="d-flex align-center flex-grow-1">
                <Component :is="tab.component" />
                <div class="text-menu">{{ tab.title }}</div>
              </div>
              <div v-if="tab.hasCount" :key="count" class="counter">{{ count }}</div>
            </div>
          </v-list-item>
        </div>
      </div>
      <div class="settings-block">
        <v-list-item class="menu-item" to="/settings" active-class="active" router>
          <div class="w-100 d-flex align-center">
            <SettingsIcon />
            <div class="text-menu">Тех поддержка</div>
          </div>
        </v-list-item>
      </div>
    </div>
  </v-navigation-drawer>
  <Header @drawer-click="drawer = !drawer" />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import AppLogo from '@/components/Svg/AppLogo.vue'
import SettingsIcon from '@/components/Icons/SettingsIcon.vue'
import { useDisplay } from 'vuetify'
import Header from '@/components/Header.vue'

const { xs, sm, md } = useDisplay()

defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  count: {
    type: Number,
    default: null,
  },
})
const drawer = ref(true)

const isMobile = computed(() => xs.value || sm.value || md.value)

const setDrawer = () => {
  drawer.value = !isMobile.value
}

watch(isMobile, setDrawer)

onMounted(setDrawer)
</script>
<style lang="scss">
.menu {
  background: var(--menu-color);
  max-height: 100vh;
  height: 100%;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .menu-item {
    border-radius: 8px;
    padding: 10px 16px;

    > div {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 12px;
      width: 20px;
    }

    &.active {
      background: var(--active-menu);
    }
  }

  .logo-wrapper {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 100%;
    }
  }

  .navbar,
  .settings-block {
    padding: 24px 16px;
  }

  .top-menu-wrapper {
    flex: 1;
  }

  .v-list-item__content {
    width: 100%;
  }
}
</style>
