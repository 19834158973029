<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="141" height="78" viewBox="0 0 141 78" fill="none">
    <mask
      id="mask0_4601_31366"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="141"
      height="78"
    >
      <path d="M141 0H0V77.04H141V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4601_31366)">
      <mask
        id="mask1_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask1_4601_31366)">
        <path
          d="M31.3403 1.38988H38.4103C40.8603 1.38988 42.8703 3.38988 42.8703 5.85988V30.2999C42.8703 32.6199 40.8703 34.6099 38.5603 34.6099H31.4903C29.0403 34.6099 27.0303 32.6199 27.0303 30.1499V5.68988C27.0303 3.38988 28.8803 1.37988 31.3403 1.37988V1.38988ZM31.3403 30.6199H38.4103V5.38988H31.3403V30.6199Z"
          fill="#2C2A3B"
        />
      </g>
      <mask
        id="mask2_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask2_4601_31366)">
        <path
          d="M49.6299 34.6096H45.1699V1.38965H49.6299V15.2396H56.6999V1.38965H61.1399V34.6096H56.6999V19.2296H49.6299V34.6096Z"
          fill="#2C2A3B"
        />
      </g>
      <mask
        id="mask3_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask3_4601_31366)">
        <path
          d="M65.4598 37.9905H62.5398L62.0898 30.6105H64.5398L67.9198 5.24047H66.5298L65.6098 1.23047H76.2198C78.2098 1.23047 79.9098 2.77047 79.9098 4.77047L81.5998 30.4605H84.0598L83.2898 37.8405H80.0698L79.2998 34.4505H66.2398L65.4698 37.9905H65.4598ZM68.6798 30.6105H77.2798L76.0598 5.39047H72.0698C71.2998 10.6205 68.6798 30.6105 68.6798 30.6105Z"
          fill="#2C2A3B"
        />
      </g>
      <mask
        id="mask4_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask4_4601_31366)">
        <path
          d="M97.4097 34.6096H92.9697V1.38965H97.4097V15.2396H104.48V1.38965H108.94V34.6096H104.48V19.2296H97.4097V34.6096Z"
          fill="#F0471D"
        />
      </g>
      <mask
        id="mask5_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask5_4601_31366)">
        <path
          d="M110.79 1.38965H123.24V5.38965H119.55V34.7696H115.09V5.38965H110.79V1.38965Z"
          fill="#F0471D"
        />
      </g>
      <mask
        id="mask6_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask6_4601_31366)">
        <path
          d="M136.61 12.3196L129.54 34.6096H125.08V1.38965H129.54V23.8496L136.61 1.38965H141.07V34.6096H136.61V12.3196Z"
          fill="#F0471D"
        />
      </g>
      <mask
        id="mask7_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask7_4601_31366)">
        <path
          d="M20.43 1.39016H14.61V-0.0898438H10.15V1.39016H4.14C1.84 1.39016 0 3.24016 0 5.54016V30.3002C0 32.6202 1.84 34.4602 4.14 34.4602H10.15V38.0002H14.6V34.4602H20.43C22.74 34.4602 24.57 32.6202 24.57 30.3002V5.54016C24.57 3.24016 22.73 1.38016 20.43 1.38016V1.39016ZM4.45 30.6202V5.39016H10.16V30.6202H4.45ZM20.29 30.6202H14.62V5.39016H20.29V30.6202Z"
          fill="#2C2A3B"
        />
      </g>
      <mask
        id="mask8_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask8_4601_31366)">
        <path
          d="M6.27016 46.0802H6.53016C7.37016 46.0802 8.14016 46.2802 8.82016 46.6402C9.50016 47.0202 10.0302 47.5302 10.4002 48.1902C10.7902 48.8502 10.9702 49.5902 10.9702 50.4002C10.9702 51.2102 10.7902 51.9502 10.4102 52.6102C10.0302 53.2602 9.51016 53.7802 8.83016 54.1602C8.15016 54.5402 7.40016 54.7202 6.54016 54.7302H6.27016V56.3302H5.37016V54.7302H5.16016C4.30016 54.7302 3.53016 54.5502 2.85016 54.1702C2.16016 53.8102 1.63016 53.3002 1.25016 52.6302C0.860156 51.9502 0.660156 51.2102 0.660156 50.4002C0.660156 49.5902 0.860156 48.8302 1.25016 48.1702C1.63016 47.5102 2.15016 46.9802 2.85016 46.6202C3.53016 46.2402 4.30016 46.0602 5.16016 46.0602H5.37016V44.4902H6.27016V46.0602V46.0802ZM5.17016 46.8502C4.07016 46.8502 3.20016 47.1702 2.55016 47.8102C1.90016 48.4602 1.57016 49.3302 1.57016 50.4102C1.57016 51.4502 1.87016 52.2902 2.47016 52.9402C3.09016 53.5702 3.90016 53.9202 4.91016 53.9602H5.36016V46.8402H5.16016L5.17016 46.8502ZM6.27016 46.8502V53.9902H6.48016C7.58016 53.9902 8.45016 53.6602 9.10016 53.0002C9.75016 52.3502 10.0602 51.4802 10.0602 50.4102C10.0602 49.3402 9.76016 48.5402 9.16016 47.8902C8.56016 47.2402 7.74016 46.9002 6.73016 46.8502H6.26016H6.27016Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask9_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask9_4601_31366)">
        <path
          d="M12.1904 51.7099C12.1904 50.9299 12.3404 50.2199 12.6404 49.5999C12.9404 48.9699 13.3804 48.4899 13.9204 48.1399C14.4804 47.7899 15.1104 47.6099 15.8004 47.6099C16.8804 47.6099 17.7604 47.9999 18.4204 48.7499C19.1004 49.4999 19.4304 50.5099 19.4304 51.7599V51.9399C19.4304 52.7199 19.2804 53.4299 18.9804 54.0599C18.6804 54.6899 18.2604 55.1699 17.7004 55.5099C17.1604 55.8599 16.5204 56.0199 15.8204 56.0199C14.7504 56.0199 13.8804 55.6399 13.2004 54.8899C12.5204 54.1199 12.1904 53.1299 12.1904 51.8799V51.6999V51.7099ZM13.0904 51.9499C13.0904 52.9299 13.3304 53.7299 13.8404 54.3399C14.3404 54.9599 15.0004 55.2699 15.8104 55.2699C16.6204 55.2699 17.2704 54.9499 17.7804 54.3399C18.2804 53.7199 18.5304 52.8899 18.5304 51.8699V51.7099C18.5304 51.0899 18.4104 50.5199 18.1804 50.0099C17.9504 49.4999 17.6204 49.0899 17.2204 48.8099C16.8004 48.5399 16.3304 48.3899 15.7904 48.3899C14.9904 48.3899 14.3404 48.7099 13.8304 49.3399C13.3204 49.9599 13.0804 50.7899 13.0804 51.7999V51.9699L13.0904 51.9499Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask10_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask10_4601_31366)">
        <path
          d="M27.5296 55.88H26.6196V52.17H22.2596V55.88H21.3496V47.77H22.2596V51.41H26.6196V47.77H27.5296V55.88Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask11_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask11_4601_31366)">
        <path
          d="M29.7396 55.13L30.1296 54.62C30.7596 53.76 31.1196 52.51 31.2296 50.87L31.3596 47.77H36.1497V55.13H36.9897V58.28H36.0996V55.89H29.9996V58.28H29.1396V55.13H29.7296H29.7396ZM30.7796 55.13H35.2596V48.61H32.2296L32.1196 50.72C32.0296 52.66 31.5796 54.12 30.7796 55.13Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask12_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask12_4601_31366)">
        <path d="M48.72 55.88H47.81V48.55H43.44V55.88H42.54V47.77H48.72V55.88Z" fill="#91929E" />
      </g>
      <mask
        id="mask13_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask13_4601_31366)">
        <path
          d="M50.6299 51.7099C50.6299 50.9299 50.7799 50.2199 51.0799 49.5999C51.3799 48.9699 51.8099 48.4899 52.3599 48.1399C52.9199 47.7899 53.5399 47.6099 54.2399 47.6099C55.3099 47.6099 56.1799 47.9999 56.8599 48.7499C57.5199 49.4999 57.8699 50.5099 57.8699 51.7599V51.9399C57.8699 52.7199 57.7199 53.4299 57.4199 54.0599C57.0999 54.6899 56.6799 55.1699 56.1399 55.5099C55.5799 55.8599 54.9499 56.0199 54.2599 56.0199C53.1799 56.0199 52.2999 55.6399 51.6399 54.8899C50.9599 54.1199 50.6299 53.1299 50.6299 51.8799V51.6999V51.7099ZM51.5199 51.9499C51.5199 52.9299 51.7799 53.7299 52.2699 54.3399C52.7799 54.9599 53.4499 55.2699 54.2599 55.2699C55.0699 55.2699 55.7199 54.9499 56.2199 54.3399C56.7299 53.7199 56.9799 52.8899 56.9799 51.8699V51.7099C56.9799 51.0899 56.8699 50.5199 56.6499 50.0099C56.4099 49.4999 56.0899 49.0899 55.6699 48.8099C55.2599 48.5399 54.7799 48.3899 54.2499 48.3899C53.4499 48.3899 52.7899 48.7099 52.2899 49.3399C51.7799 49.9599 51.5199 50.7899 51.5199 51.7999V51.9699V51.9499Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask14_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask14_4601_31366)">
        <path
          d="M58.9904 55.13L59.4004 54.62C60.0304 53.76 60.3904 52.51 60.4804 50.87L60.6304 47.77H65.4204V55.13H66.2604V58.28H65.3604V55.89H59.2604V58.28H58.4004L58.4204 55.13H58.9904ZM60.0304 55.13H64.5204V48.61H61.4804L61.3904 50.72C61.3004 52.66 60.8504 54.12 60.0304 55.13Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask15_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask15_4601_31366)">
        <path
          d="M67.9604 55.13L68.3503 54.62C68.9803 53.76 69.3403 52.51 69.4503 50.87L69.5804 47.77H74.3704V55.13H75.2104V58.28H74.3204V55.89H68.2204V58.28H67.3604V55.13H67.9503H67.9604ZM69.0004 55.13H73.4903V48.61H70.4503L70.3403 50.72C70.2503 52.66 69.8104 54.12 69.0004 55.13Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask16_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask16_4601_31366)">
        <path
          d="M80.1002 56.0301C79.4102 56.0301 78.7902 55.8701 78.2302 55.5201C77.6702 55.1901 77.2402 54.7101 76.9202 54.1001C76.6202 53.5001 76.4502 52.8101 76.4502 52.0501V51.7301C76.4502 50.9501 76.6002 50.2401 76.9202 49.6201C77.2202 48.9901 77.6402 48.5101 78.1702 48.1401C78.7102 47.7801 79.3002 47.6001 79.9302 47.6001C80.9102 47.6001 81.6902 47.9501 82.2802 48.6101C82.8502 49.2901 83.1402 50.2101 83.1402 51.3701V51.8801H77.3502V52.0401C77.3502 52.9601 77.6102 53.7301 78.1302 54.3401C78.6602 54.9601 79.3202 55.2601 80.1202 55.2601C80.6002 55.2601 81.0202 55.1701 81.4002 54.9901C81.7602 54.8301 82.0902 54.5401 82.3902 54.1601L82.9602 54.5801C82.3002 55.5301 81.3302 56.0101 80.0802 56.0101L80.1002 56.0301ZM79.9302 48.3801C79.2502 48.3801 78.6802 48.6301 78.2302 49.1201C77.7602 49.6201 77.4802 50.2901 77.3902 51.1201H82.2602V51.0101C82.2302 50.2401 82.0002 49.6101 81.6002 49.1101C81.1802 48.6101 80.6202 48.3701 79.9402 48.3701L79.9302 48.3801Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask17_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask17_4601_31366)">
        <path
          d="M91.4304 51.9101C91.4304 53.1701 91.1404 54.1801 90.5904 54.9201C90.0304 55.6701 89.2804 56.0301 88.3304 56.0301C87.2304 56.0301 86.3704 55.6401 85.7904 54.8701V59.0001H84.9004V47.7701H85.7304L85.7704 48.9301C86.3604 48.0601 87.2004 47.6201 88.3204 47.6201C89.2804 47.6201 90.0504 48.0001 90.6104 48.7301C91.1504 49.4501 91.4404 50.4801 91.4404 51.7901V51.9101H91.4304ZM90.5304 51.7601C90.5304 50.7201 90.3204 49.9101 89.8804 49.3001C89.4604 48.7001 88.8704 48.4001 88.1004 48.4001C87.5604 48.4001 87.0904 48.5401 86.6804 48.8101C86.2904 49.0601 85.9904 49.4601 85.7804 49.9501V53.8501C85.9904 54.3201 86.3104 54.6601 86.7004 54.9201C87.1104 55.1601 87.5704 55.2801 88.1204 55.2801C88.8904 55.2801 89.4804 54.9801 89.9004 54.3801C90.3204 53.7801 90.5304 52.9001 90.5304 51.7601Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask18_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask18_4601_31366)">
        <path
          d="M99.5096 52.14H98.4196V55.88H97.5196V52.14H96.4496L93.4496 55.88H92.3096L95.6696 51.73L92.6796 47.77H93.7696L96.4296 51.34H97.5196V47.77H98.4196V51.34H99.5196L102.19 47.77H103.29L100.29 51.73L103.65 55.88H102.51L99.5096 52.14Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask19_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask19_4601_31366)">
        <path
          d="M107.3 52.17H106.05V55.88H105.14V47.77H106.05V51.37H107.28L110.27 47.77H111.4L108.07 51.71L111.76 55.88H110.58L107.3 52.17Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask20_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask20_4601_31366)">
        <path
          d="M118.39 47.77H119.29V55.88H118.39V49.21L114 55.88H113.12V47.77H114V54.45L118.39 47.77Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask21_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask21_4601_31366)">
        <path
          d="M7.50008 73.91H6.60008V66.58H2.23008V73.91H1.33008V65.79H7.50008V73.91Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask22_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask22_4601_31366)">
        <path
          d="M16.4404 69.9299C16.4404 71.1899 16.1504 72.1999 15.6004 72.9399C15.0404 73.6899 14.2904 74.0499 13.3504 74.0499C12.2404 74.0499 11.3804 73.6599 10.7904 72.8899V77.0299H9.90039V65.7799H10.7304L10.7804 66.9399C11.3704 66.0699 12.2104 65.6299 13.3304 65.6299C14.3104 65.6299 15.0604 66.0099 15.6204 66.7399C16.1604 67.4599 16.4504 68.4899 16.4504 69.7999V69.9199L16.4404 69.9299ZM15.5404 69.7799C15.5404 68.7399 15.3304 67.9299 14.9104 67.3299C14.4904 66.7299 13.8904 66.4299 13.1304 66.4299C12.5704 66.4299 12.1104 66.5699 11.7104 66.8399C11.3004 67.0999 11.0004 67.4899 10.7904 67.9799V71.8799C11.0204 72.3499 11.3204 72.6899 11.7204 72.9499C12.1104 73.1899 12.5904 73.3099 13.1404 73.3099C13.8904 73.3099 14.4804 73.0099 14.9004 72.4099C15.3204 71.8099 15.5304 70.9299 15.5304 69.7899L15.5404 69.7799Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask23_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask23_4601_31366)">
        <path
          d="M17.9297 69.7401C17.9297 68.9601 18.0797 68.2501 18.3797 67.6301C18.6797 67.0001 19.1197 66.5201 19.6597 66.1701C20.2197 65.8201 20.8497 65.6401 21.5397 65.6401C22.6197 65.6401 23.4997 66.0301 24.1597 66.7801C24.8397 67.5301 25.1697 68.5401 25.1697 69.7901V69.9701C25.1697 70.7701 25.0197 71.4601 24.7197 72.0901C24.4197 72.7201 23.9997 73.2001 23.4397 73.5401C22.8997 73.8901 22.2597 74.0501 21.5597 74.0501C20.4897 74.0501 19.6197 73.6701 18.9397 72.9201C18.2597 72.1701 17.9297 71.1601 17.9297 69.9101V69.7301V69.7401ZM18.8297 69.9801C18.8297 70.9601 19.0697 71.7601 19.5797 72.3701C20.0797 72.9901 20.7397 73.3001 21.5497 73.3001C22.3597 73.3001 23.0097 72.9801 23.5197 72.3701C24.0197 71.7501 24.2697 70.9201 24.2697 69.9001V69.7301C24.2697 69.1101 24.1497 68.5401 23.9197 68.0301C23.6997 67.5201 23.3597 67.1101 22.9597 66.8301C22.5397 66.5601 22.0697 66.4101 21.5297 66.4101C20.7297 66.4101 20.0797 66.7301 19.5697 67.3601C19.0697 67.9801 18.8197 68.8101 18.8197 69.8101V69.9801H18.8297Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask24_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask24_4601_31366)">
        <path
          d="M30.1803 74.0599C29.4903 74.0599 28.8703 73.8899 28.3103 73.5499C27.7503 73.2199 27.3103 72.7399 27.0003 72.1299C26.7003 71.5299 26.5303 70.8299 26.5303 70.0799V69.7599C26.5303 68.9799 26.6803 68.2699 26.9803 67.6499C27.2803 67.0199 27.7003 66.5399 28.2503 66.1699C28.7903 65.8099 29.3803 65.6299 30.0103 65.6299C30.9903 65.6299 31.7703 65.9799 32.3603 66.6399C32.9303 67.3199 33.2203 68.2399 33.2203 69.3999V69.9099H27.4303V70.0799C27.4303 70.9999 27.6903 71.7699 28.2203 72.3799C28.7503 72.9999 29.4103 73.2999 30.2103 73.2999C30.6903 73.2999 31.1103 73.2099 31.4903 73.0399C31.8503 72.8599 32.1803 72.5699 32.4803 72.1999L33.0503 72.6199C32.3903 73.5799 31.4203 74.0499 30.1703 74.0499L30.1803 74.0599ZM30.0103 66.4099C29.3303 66.4099 28.7603 66.6699 28.3103 67.1599C27.8403 67.6399 27.5503 68.3199 27.4603 69.1499H32.3303V69.0599C32.3003 68.2799 32.0703 67.6399 31.6503 67.1499C31.2303 66.6699 30.6903 66.4099 30.0103 66.4099Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask25_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask25_4601_31366)">
        <path
          d="M37.1903 70.19H35.9403V73.91H35.0303V65.79H35.9403V69.41H37.1603L40.1403 65.79H41.2703L37.9403 69.74L41.6503 73.91H40.4603L37.1903 70.19Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask26_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask26_4601_31366)">
        <path
          d="M48.7803 66.56H45.8103V73.91H44.9203V66.56H42.0703V65.79H48.7803V66.56Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask27_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask27_4601_31366)">
        <path
          d="M49.4199 69.7401C49.4199 68.9601 49.5699 68.2501 49.8899 67.6301C50.1899 67.0001 50.6099 66.5201 51.1699 66.1701C51.7099 65.8201 52.3499 65.6401 53.0399 65.6401C54.1199 65.6401 54.9999 66.0301 55.6799 66.7801C56.3399 67.5301 56.6899 68.5401 56.6899 69.7901V69.9701C56.6899 70.7701 56.5299 71.4601 56.2199 72.0901C55.9199 72.7201 55.4999 73.2001 54.9399 73.5401C54.3999 73.8901 53.7599 74.0501 53.0599 74.0501C51.9899 74.0501 51.1199 73.6701 50.4399 72.9201C49.7799 72.1701 49.4299 71.1601 49.4299 69.9101V69.7301L49.4199 69.7401ZM50.3199 69.9801C50.3199 70.9601 50.5799 71.7601 51.0699 72.3701C51.5799 72.9901 52.2299 73.3001 53.0399 73.3001C53.8499 73.3001 54.5199 72.9801 55.0099 72.3701C55.5199 71.7501 55.7599 70.9201 55.7599 69.9001V69.7301C55.7599 69.1101 55.6499 68.5401 55.4099 68.0301C55.1899 67.5201 54.8699 67.1101 54.4499 66.8301C54.0299 66.5601 53.5599 66.4101 53.0199 66.4101C52.2199 66.4101 51.5699 66.7301 51.0599 67.3601C50.5599 67.9801 50.3099 68.8101 50.3099 69.8101V69.9801H50.3199Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask28_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask28_4601_31366)">
        <path
          d="M58.6299 73.91V65.79H61.6099C62.6299 65.79 63.4299 65.99 63.9799 66.35C64.5399 66.71 64.8099 67.25 64.8099 67.96C64.8099 68.37 64.6899 68.73 64.4499 69.04C64.1899 69.36 63.8299 69.6 63.3699 69.73C63.9099 69.84 64.3299 70.06 64.6499 70.42C64.9799 70.77 65.1299 71.19 65.1299 71.65C65.1299 72.36 64.8699 72.91 64.3499 73.31C63.8199 73.7 63.0699 73.9 62.0999 73.9H58.6399L58.6299 73.91ZM59.5199 69.44H61.7999C63.2199 69.41 63.9299 68.93 63.9299 67.99C63.9299 67.49 63.7299 67.13 63.3399 66.91C62.9499 66.68 62.3799 66.56 61.6199 66.56H59.5199V69.44ZM59.5199 70.18V73.18H62.1299C62.8099 73.18 63.3199 73.04 63.6999 72.79C64.0599 72.52 64.2399 72.14 64.2399 71.66C64.2399 71.21 64.0599 70.85 63.6999 70.59C63.3199 70.32 62.8099 70.18 62.1299 70.18H59.5199Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask29_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask29_4601_31366)">
        <path
          d="M79.1701 73.9102H78.2301V68.6802H72.0001V73.9102H71.0801V62.9902H72.0001V67.9002H78.2301V62.9902H79.1701V73.9102Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask30_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask30_4601_31366)">
        <path
          d="M89.1405 63.7802H85.4005V73.9102H84.4705V63.7802H80.7305V62.9902H89.1405V63.7802Z"
          fill="#91929E"
        />
      </g>
      <mask
        id="mask31_4601_31366"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="78"
      >
        <path d="M0 0V77.16H141.13V0" fill="white" />
      </mask>
      <g mask="url(#mask31_4601_31366)">
        <path
          d="M98.0897 62.9902H99.0097V73.9102H98.0897V64.5702L91.8697 73.9102H90.9297V62.9902H91.8697V72.3302L98.0897 62.9902Z"
          fill="#91929E"
        />
      </g>
    </g>
  </svg>
</template>
