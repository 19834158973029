<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.19961 12.7996C10.2924 12.7996 12.7996 10.2924 12.7996 7.19961C12.7996 4.10681 10.2924 1.59961 7.19961 1.59961C4.10681 1.59961 1.59961 4.10681 1.59961 7.19961C1.59961 10.2924 4.10681 12.7996 7.19961 12.7996Z"
      stroke="#A6A6A6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6002 13.6002L11.2002 11.2002"
      stroke="#A6A6A6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
