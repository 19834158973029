import moment from 'moment'

import { api } from '@/api/Api'

import {
  PROJECT_REQUEST_STATUSES,
  REPORTS_IDS,
  REPORTS_STATUSES,
  REPORTS_TIME_STATUSES,
  REPORTS_TYPES,
} from '@/constants'

import FormText from '@/components/Form/FormText'
import FormTextarea from '@/components/Form/FormTextarea'
import FormSelect from '@/components/Form/FormSelect'
import FormCombobox from '@/components/Form/FormCombobox'
import FormFile from '@/components/Form/FormFile'
import FormRadio from '@/components/Form/FormRadio'
import FormCheckbox from '@/components/Form/FormCheckbox'
import FormTextRules from '@/components/Form/FormTextRules'
import FormDate from '@/components/Form/FormDate'
import FormDropFile from '@/components/Form/FormDropFile'
import FormNumber from '@/components/Form/FormNumber'
import FormActionButton from '@/components/Form/FormActionButton'
import FormTime from '@/components/Form/FormTime'
import FormAgree from '@/components/Form/FormAgree'

export function getFormInput(type) {
  switch (type) {
    case 'hidden': {
      return ''
    }
    case 'text': {
      return 'FormText'
    }
    case 'email': {
      return 'FormText'
    }
    case 'date': {
      return 'FormDate'
    }
    case 'datetime-local': {
      return 'FormTime'
    }
    case 'dropFile': {
      return 'FormDropFile'
    }
    case 'textarea': {
      return 'FormTextarea'
    }
    case 'select': {
      return 'FormSelect'
    }
    case 'select-other': {
      return 'FormSelect'
    }
    case 'combobox': {
      return 'FormCombobox'
    }
    case 'group': {
      return ''
    }
    case 'actionButton': {
      return 'FormActionButton'
    }
    case 'checkbox': {
      return 'FormCheckbox'
    }
    case 'radio': {
      return 'FormRadio'
    }
    case 'file': {
      return 'FormFile'
    }
    case 'rules': {
      return 'FormTextRules'
    }
    case 'number': {
      return 'FormNumber'
    }
    case 'agree': {
      return 'FormAgree'
    }
    default: {
      return ''
    }
  }
}

export const inputs = {
  FormAgree,
  FormText,
  FormTextarea,
  FormSelect,
  FormCombobox,
  FormFile,
  FormRadio,
  FormCheckbox,
  FormTextRules,
  FormDate,
  FormDropFile,
  FormActionButton,
  FormNumber,
  FormTime,
}

export function updateValue(fieldsForm, data) {
  const { value, id } = data
  fieldsForm.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

export function focusErrorInput({ id }) {
  const inputId = '#' + id
  const input = document.querySelector(inputId)
  if (!input) {
    return
  }
  input.scrollIntoView({ behavior: 'smooth', block: 'center' })
  input.focus()
}

export function showDocument(val) {
  if (val.ref) {
    val.ref.openDocument(val)
  }
}

export function downloadFileWithLinkData(response, name) {
  const link = document.createElement('a')
  link.href = response.link
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export function downloadFileData(response, name) {
  const url = window.URL.createObjectURL(
    new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    }),
  )

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export function downloadFilePdfData(response) {
  const file = new Blob([response], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.setAttribute('target', '_blank')
  link.click()
  link.remove()
}

export function getStatusProgram(status) {
  switch (status) {
    case PROJECT_REQUEST_STATUSES.DRAFT:
      return 'Черновик'
    case PROJECT_REQUEST_STATUSES.NEW:
      return 'На рассмотрении у менеджера'
    case PROJECT_REQUEST_STATUSES.REJECTED:
      return 'На доработке'
    case PROJECT_REQUEST_STATUSES.IMPROVED:
      return 'На рассмотрении у менеджера'
    case PROJECT_REQUEST_STATUSES.SEND_FOR_APPROVE:
      return 'На рассмотрении у монитора'
    case PROJECT_REQUEST_STATUSES.REVIEWED:
      return 'Одобрен монитором. На рассмотрении у менеджера'
    case PROJECT_REQUEST_STATUSES.ACCEPTED:
      return 'Принят'
    default:
      return status
  }
}

export function getNumberWithSpaces(sum) {
  const localSum = sum ? String(sum).replace('.', ',') : 0
  return localSum ? (localSum + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0
}

export function getFormatDate(date) {
  const isValidDate = moment(date, true).isValid()
  if (!isValidDate) {
    return ''
  }
  moment.locale('ru')
  return moment(date).format('DD MMMM YYYY')
}

export function getFormatDateDefault(date) {
  moment.locale('ru')
  const formatDate = moment(date).format()
  const converted = moment(formatDate).format('DD.MM.YYYY')
  return converted || ''
}

export function getFormatDateDefaultShort(date) {
  moment.locale('ru')
  const formatDate = moment(date).format()
  const converted = moment(formatDate).format('DD.MM.YY')
  return converted || ''
}

export function getFormatDateTime(date) {
  moment.locale('ru')
  const formatDate = moment(date).format()
  const converted = moment(formatDate).format('DD.MM.YYYY, HH:mm')
  return converted || ''
}

export function getFormatDateTimeForBackend(date) {
  moment.locale('ru')
  const formatDate = moment(date).format()

  const converted = moment(formatDate).format('DD.MM.YYYY HH:mm:ss')
  return converted || ''
}

export function getPeriodId(period) {
  switch (period) {
    case REPORTS_TYPES.FIRST_QUARTER:
      return REPORTS_IDS.FIRST_QUARTER
    case REPORTS_TYPES.SECOND_QUARTER:
      return REPORTS_IDS.SECOND_QUARTER
    case REPORTS_TYPES.THIRD_QUARTER:
      return REPORTS_IDS.THIRD_QUARTER
    default:
      return REPORTS_IDS.ANNUAL
  }
}

export function getReportPeriod(type) {
  const reportTypeId = getPeriodId(type)

  return '|'.repeat(+reportTypeId)
}

export function getReportPeriodById(id) {
  return '|'.repeat(+id + 1)
}

export function getLabelStructure(data) {
  switch (data) {
    case 'equipment':
      return '— Приобретение оборудования'
    case 'fot':
      return '— Выплаты персоналу, всего (включая начисления на фонд оплаты труда)'
    case 'software':
      return '— Приобретение программного обеспечения'
    case 'services':
      return '— Закупка работ и услуг'
    default:
      return ''
  }
}

export function getExpenditureType(type) {
  switch (type) {
    case 'equipment':
      return 'Оборудование'
    case 'fot':
      return 'Выплаты персоналу'
    case 'ndfl':
      return 'НДФЛ'
    case 'contributions':
      return 'Взносы'
    case 'salary':
      return 'Заработная плата'
    case 'software':
      return 'ПО'
    case 'services':
      return 'Работы и услуги'
    default:
      return ''
  }
}

export function getLabelHistoryItem(item) {
  return item.type !== 'annual'
    ? `Отчет за ${getReportPeriod(item.type)} квартал `
    : `Годовой отчет`
}

export function getFilterArrayByField(fields, key) {
  return fields?.reduce((acc, obj) => {
    const property = obj[key] || 0
    acc[property] = acc[property] || []
    acc[property].push(obj)
    return acc
  }, {})
}

export async function clearStash(hash) {
  try {
    await api.stash().clearStash(hash)
  } catch (err) {
    console.log(err)
  }
}

export function groupFields(fields, key) {
  return fields?.reduce((acc, obj) => {
    const property = obj[key] || 0
    acc[property] = acc[property] || []
    acc[property].push(obj)
    return acc
  }, {})
}

export function getTimeStatusReport(color) {
  switch (color) {
    case REPORTS_TIME_STATUSES.SOON:
      return 'pending-wrapper'
    case REPORTS_TIME_STATUSES.PENDING:
      return 'success-wrapper'
    case REPORTS_TIME_STATUSES.IN_PROGRESS:
      return 'info-wrapper'
    case REPORTS_TIME_STATUSES.LATE:
      return 'error-wrapper'
    default:
      return 'error-wrapper'
  }
}

export function getLabelStatusReport(status) {
  switch (status) {
    case REPORTS_TIME_STATUSES.SOON:
      return 'Ожидание периода сдачи отчёта'
    case REPORTS_TIME_STATUSES.PENDING:
      return 'Ожидание периода сдачи отчёта'
    case REPORTS_TIME_STATUSES.IN_PROGRESS:
      return 'Ожидание отчета'
    case REPORTS_TIME_STATUSES.LATE:
      return 'Отчет не сдан'
    default:
      return 'Ожидание отчета'
  }
}

export function getActionReport(status) {
  switch (status) {
    case REPORTS_STATUSES.DRAFT:
      return 'Создан черновик отчета'
    case REPORTS_STATUSES.NEW:
      return 'Проверка Менеджером'
    case REPORTS_STATUSES.IMPROVED:
      return 'Проверка Менеджером'
    case REPORTS_STATUSES.REJECTED:
      return 'Доработка отчета'
    case REPORTS_STATUSES.SEND_FOR_APPROVE:
      return 'Проверка Монитором'
    case REPORTS_STATUSES.REVIEWED:
      return 'Сформировано заключение Монитора'
    case REPORTS_STATUSES.ACCEPTED:
      return 'Отчет принят'
    case REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION:
      return 'Формируется экспресс-заключение'
    case REPORTS_STATUSES.FINALIZED_CONCLUSION:
      return 'Сформировано экспресс-заключение'
    case REPORTS_STATUSES.REPEAT_FINALIZE_CONCLUSION:
      return 'Доработка финальго заключения'
    default:
      return ''
  }
}

export function round(value, decimals = 2) {
  return Number(Math.round(Number(value) + 'e' + Number(decimals)) + 'e-' + Number(decimals))
}

export function getErrorMessageParsingExcelFiles(err) {
  switch (err) {
    case 'WRONG_CONTROL_EVENTS_EXCEL':
      return 'Ошибка при чтении файла "Контрольные события". Попробуйте загрузить другой файл'
    case 'WRONG_HARDWARE_SOFTWARE_EXCEL':
      return 'Ошибка при чтении файла "Оборудование и ПО". Попробуйте загрузить другой файл'
    case 'WRONG_PERFORMANCE_EXCEL':
      return 'Ошибка при чтении файла "Показатели результативности". Попробуйте загрузить другой файл'
    case 'WRONG_YEARS_EXCEL':
      return 'Ошибка при чтении файла "Индикаторы". Попробуйте загрузить другой файл'
    case 'WRONG_IMPLEMENTATION_PLAN_EXCEL':
      return 'Ошибка при чтении файла "План мероприятий". Попробуйте загрузить другой файл'
    default:
      return ''
  }
}

export function getGroupSort(groupId, groups) {
  return groups.find((el) => el.code1C === groupId)?.sort
}

export function getCheckboxOptions(list) {
  return list?.map((el, index) => {
    return {
      id: `${el}-${index}`,
      value: el,
      name: el,
      disable: 'N',
      sort: index,
      description: '',
    }
  })
}

export function getSortList(list) {
  return list?.sort((a, b) => (+a.sort < +b.sort ? -1 : 1))
}

export function getOptions(list, type) {
  if (type === 'checkbox' || type === 'radio' || type === 'combobox') {
    return getCheckboxOptions(list)
  }

  return list
}

export function getFileType(fileName) {
  if (!fileName) {
    return ''
  }
  return fileName?.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
}

export function getFileIcon(fileName) {
  const fileType = getFileType(fileName)

  switch (fileType) {
    case 'pdf':
      return 'mdi-file-pdf-box'
    case 'png':
      return 'mdi-image'
    case 'jpg':
      return 'mdi-image'
    case 'jpeg':
      return 'mdi-image'
    case 'xls':
      return 'mdi-table'
    case 'xlsx':
      return 'mdi-table'
    case 'gif':
      return 'mdi-image'
    default:
      return 'mdi-file'
  }
}

export const findMaxNumber = (numbers) => {
  if (numbers.length === 0) {
    return null
  }

  return numbers.reduce((acc, number) => (number > acc ? number : acc), numbers[0])
}

export const getStringForGetRequest = (obj) => {
  if (!obj) {
    return ''
  }

  const params = new URLSearchParams()

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value) => {
        if (value) {
          params.append(`${key}[]`, value)
        }
      })
    } else {
      if (obj[key]) {
        params.append(key, obj[key])
      }
    }
  }

  return params.toString()
}
