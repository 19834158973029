<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M14.1667 10.04H12.5L10.8333 14.2067L9.16667 5.87337L7.5 10.04H5.83333M4.16667 2.54004H15.8333C16.7538 2.54004 17.5 3.28623 17.5 4.20671V15.8734C17.5 16.7938 16.7538 17.54 15.8333 17.54H4.16667C3.24619 17.54 2.5 16.7938 2.5 15.8734V4.20671C2.5 3.28623 3.24619 2.54004 4.16667 2.54004Z"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
