const state = {
  drawer: true,
  pageTitle: '',
}

const getters = {
  menuDrawer(state) {
    return state.drawer
  },

  pageTitle(state) {
    return state.pageTitle
  },
}

const mutations = {
  setDrawer(state, value) {
    state.drawer = value
  },

  setPageTitle(state, value) {
    state.pageTitle = value
  },
}

export const app = {
  namespaced: true,
  state,
  getters,
  mutations,
}
