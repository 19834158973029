<template>
  <div class="tasks-content">
    <div class="menu-tasks">
      <template v-for="(item, index) in menu">
        <div
          v-if="item.isShow"
          :key="item.id"
          :class="['menu-item', { active: activeMenu === index }]"
          @click="() => handleNavClick(index)"
        >
          <span class="title">
            {{ item.title }}
          </span>
          <span v-if="item.count" class="count">{{ item.count }}</span>
        </div>
      </template>
    </div>
    <div>
      <Component :is="menu[activeMenu].component" :type="menu[activeMenu].name" />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'
import ProjectTasksList from '@/components/Tasks/ProjectTasksList.vue'
import NotesList from '@/components/Notes/NotesList.vue'

const store = useStore()

const isExpert = computed(() => store.getters['user/isExpert'])

const props = defineProps({
  countPoints: {
    type: Number,
  },
  activeCountPoints: {
    type: Number,
  },
})

const menu = ref([
  {
    id: uuidv4(),
    title: 'Все',
    count: 1,
    countName: 'countPoints',
    name: '',
    isShow: true,
    component: ProjectTasksList,
  },
  {
    id: uuidv4(),
    title: 'Требуют действий',
    count: 1,
    name: 'active',
    countName: 'activeCountPoints',
    isShow: true,
    component: ProjectTasksList,
  },
  {
    id: uuidv4(),
    title: 'Отработаны',
    name: 'closed',
    isShow: true,
    component: ProjectTasksList,
  },
  {
    id: uuidv4(),
    title: 'Экспертиза',
    name: 'expert',
    isShow: !!isExpert.value,
    component: ProjectTasksList,
  },
  {
    id: uuidv4(),
    title: 'Просроченные',
    name: 'expired',
    isShow: true,
    component: ProjectTasksList,
  },
  {
    id: uuidv4(),
    title: 'Черновики',
    name: 'note',
    isShow: true,
    component: NotesList,
  },
])
const activeMenu = ref(0)

const handleNavClick = (index) => {
  activeMenu.value = index
}

const setDefaultValue = () => {
  if (props.activeCountPoints) {
    menu.value[1].count = props.activeCountPoints
  }

  if (props.countPoints) {
    menu.value[0].count = props.countPoints
  }
}

watch(props, setDefaultValue)

onMounted(setDefaultValue)
</script>
<style scoped>
.tasks-content {
  border-top: 1px solid var(--menu-color);
  display: grid;
  grid-template-columns: 190px 1fr;
  align-items: stretch;
  min-height: 100%;
  flex: 1;

  @media (max-width: 765px) {
    grid-template-columns: 1fr;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: auto;

    > div {
      flex: 1;
      width: 100%;
    }
  }

  .menu-tasks {
    border-right: 1px solid var(--menu-color);
    padding: 16px;

    @media (max-width: 765px) {
      padding-bottom: 0;
      border: 0;
    }

    .menu-item {
      border-left: 2px solid transparent;
      color: var(--text-caption);
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      padding: 10px 16px;
      cursor: pointer;
      transition: all 0.3s;
      flex-wrap: nowrap;
      gap: 8px;
      display: flex;
      align-items: center;

      .title {
        flex: 0;

        @media (max-width: 765px) {
          flex: 1;
        }
      }

      .count {
        border-radius: 50%;
        background: var(--primary);
        color: var(--light-background);
        min-width: 16px;
        height: 16px;
        padding: 0 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 16px;
      }

      &:hover,
      &.active {
        color: var(--color-neutral-darkest);
        background: var(--primary-ligth);
        border-color: var(--primary);
      }
    }
  }
}
</style>
