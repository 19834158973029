<template>
  <div class="task-page">
    <ProjectForm />
  </div>
</template>
<script setup>
import ProjectForm from '@/components/Project/ProjectForm.vue'
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const setPageTitle = () => {
  store.commit('app/setPageTitle', 'Анкета получателя поддержки')
}

onMounted(setPageTitle)
</script>

<style lang="scss">
.form-container {
  max-width: 850px;
  width: 100%;
}
</style>
