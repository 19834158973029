<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <template v-else>
    <div v-if="notes.length" class="notes">
      <div v-for="item in notes" :key="`note-${item.id}`" class="note">
        <div>Черновик "{{ item.pointId ? item.pointName : item.name && `(${item.name})` }}"</div>
        <div class="d-flex justify-end link">
          <RouterLink
            :to="item.pointId ? `/tasks/${item.pointId}` : `/programs/create/${item.code1C}`"
          >
            <ArrowRight />
          </RouterLink>
        </div>
      </div>
    </div>
    <v-row v-else class="justify-center align-center py-6 text-grey"> Список пуст </v-row>
  </template>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import ArrowRight from '@/components/Icons/ArrowRight.vue'

const isLoading = ref(false)
const notes = ref([])

async function getNotes() {
  isLoading.value = true
  try {
    await api
      .questionnaires()
      .getApplicationNotes()
      .then((data) => {
        notes.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(getNotes)
</script>
<style lang="scss" scoped>
.notes {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .note {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: var(--menu-color);
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;

    .link {
      svg {
        color: var(--text-caption);
      }
    }

    &:hover {
      background: var(--menu-light-color);
    }
  }
}
</style>
