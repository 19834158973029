<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M7 18L3.66667 18C3.22464 18 2.80072 17.8244 2.48816 17.5118C2.17559 17.1993 2 16.7754 2 16.3333L2 3.66667C2 3.22464 2.1756 2.80072 2.48816 2.48816C2.80072 2.17559 3.22464 2 3.66667 2L7 2"
      stroke="#F0591E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 14.1668L17.5 10.0002L13.3333 5.8335"
      stroke="#F0591E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 10L7.5 10"
      stroke="#F0591E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
